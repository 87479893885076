<template>
    <div class="max">
        <div class="spinner over" v-show="formLoading">
            <b-spinner
                label="Spinning"
                class="text-primary">
            </b-spinner>
        </div>
        <ValidationObserver ref="formModalValidate" v-show="!formLoading">
            <b-row>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('type')">
                        <div class="label-as-input">
                            <span v-if="formType=='Speaking'">{{ $t('speaking_exam') }}</span>
                            <span v-else>{{ $t('written_exam') }}</span>
                        </div>
                    </b-form-group>
                    <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('date')">
                            <b-form-input type="date"
                                          v-model="formData.date"
                                          :class="errors[0] ? 'is-invalid':''">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <b-row>
                        <b-col>
                            <ValidationProvider name="start_time" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('start_time')">
                                    <b-form-input type="time"
                                                  v-model="formData.start_time"
                                                  :class="errors[0] ? 'is-invalid':''">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider name="end_time" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('end_time')">
                                    <b-form-input type="time"
                                                  v-model="formData.end_time"
                                                  :class="errors[0] ? 'is-invalid':''">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <ValidationProvider name="duration" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('meeting_duration')">
                            <b-input-group :append="$t('minute')">
                                <b-form-select v-model="formData.meeting_duration"
                                               :options="meetingDurationOptions"
                                               :class="errors[0] ? 'is-invalid':''">
                                </b-form-select>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="duration" v-slot="{valid, errors}">
                        <label>{{ $t('instructors') }}</label>
                        <checkbox-with-filter v-model="formData.instructors"
                                              :options="instructors"
                                              :validate-error="errors[0] ? errors[0]:''"
                        >
                        </checkbox-with-filter>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="available_hours" v-slot="{valid, errors}">
                        <b-form-group :label="$t('available_hours')">
                            <hours-input v-model="formData.available_hours"
                                         :validate-error="errors[0] ? errors[0]:''"
                            ></hours-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex mt-4 mb-2">
                <div class="mw-100px">
                    <b-button variant="outline-primary" @click="$emit('backForm', true)" class="mr-2">
                        {{ $t('back').toUpper() }}
                    </b-button>
                </div>
                <div class="flex-grow-1 d-flex justify-content-center">
                    <b-button variant="primary" @click="createForm" v-if="formProgress=='create'">{{ $t('save').toUpper() }}</b-button>
                    <b-button variant="primary" @click="updateForm" v-if="formProgress=='update'">{{ $t('save').toUpper() }}</b-button>
                </div>
                <div class="mw-100px">&nbsp;</div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import CheckboxWithFilter from "@/components/elements/CheckboxWithFilter";
    import HoursInput from "@/components/elements/HoursInput";
    import {ValidationProvider, ValidationObserver} from "vee-validate"

    // Services
    import PlacementExamPartService from "@/services/PlacementExamPartService"
    import StaffService from "@/services/StaffService";

    export default {
        components: {
            CheckboxWithFilter,
            HoursInput,
            ValidationProvider,
            ValidationObserver,
        },
        props: {
            placementExamId: {
                type: Number,
                default: null
            },
            formId: {
                type: Number,
                default: null
            },
            formProgress: {
                type: String,
                default: 'create',
            },
            formType: {
                type: String,
                default: null,
            },
        },
        mounted() {
            this.getInstructors();
            if(this.formId && this.formProgress=='update'){
                this.formDataClear();
                this.getForm(this.formId)
            }
        },
        data() {
            return {
                meetingDurationOptions: [
                    {value: null, 'text': this.$t('select')},
                    {value: 5, 'text': '5 '+this.$t('minute')},
                    {value: 10, 'text': '10 '+this.$t('minute')}
                ],
                instructors: [],
                formData: {
                    date: null,
                    start_time: null,
                    end_time: null,
                    meeting_duration: null,
                    available_hours: [],
                    instructors: [],
                },
                formLoading: false,
                start_time: null,
                end_time: null,
            }
        },
        methods: {
            formDataClear() {
                this.formData = {
                    date: null,
                    start_time: null,
                    end_time: null,
                    meeting_duration: null,
                    available_hours: [],
                    instructors: [],
                }
                this.$refs.formModalValidate.reset();
            },

            // Staff
            getInstructors() {
                StaffService.staffByRole('A')
                            .then(response => {
                                this.staff = [];
                                let data = response.data.data;
                                if (data) {
                                    data.forEach((item) => {
                                        this.instructors.push({id: item.user_id, name: item.name + ' ' + item.surname})
                                    })
                                }
                            });
            },

            // Get
            getForm(id) {
                this.formLoading = true
                PlacementExamPartService.get(id)
                                        .then(response => {
                                            let data = response.data.data;
                                            this.formData = {
                                                date: data.date,
                                                start_time: data.start_time,
                                                end_time: data.end_time,
                                                meeting_duration: data.meeting_duration,
                                                instructors: data.instructors,
                                                available_hours: data.available_hours,
                                            }
                                        })
                                        .then(()=>{
                                            this.formLoading = false;
                                        });
            },

            // Create
            async createForm() {
                if(this.checkPermission('placementexampart_store')){
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid) {
                        let formData = {
                            placement_exam_id: this.placementExamId,
                            type: this.formType,
                            ...this.formData
                        }
                        PlacementExamPartService.store(formData)
                                                .then(response => {
                                                    this.$toast.success(this.$t('api.' + response.data.message));
                                                    this.$refs.formModalValidate.reset();
                                                    this.formDataClear();
                                                    this.$emit('createFormSuccess', true)
                                                })
                                                .catch(e => {
                                                    this.showError(e);
                                                })

                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            // Update
            async updateForm() {
                if(this.checkPermission('placementexampart_update')){
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid && this.formId) {
                        let formData = {
                            placement_exam_id: this.placementExamId,
                            type: this.formType,
                            ...this.formData
                        }
                        PlacementExamPartService.update(this.formId, formData)
                                                .then(response => {
                                                    this.$toast.success(this.$t('api.' + response.data.message));
                                                })
                                                .then(() => {
                                                    this.formDataClear();
                                                    this.$emit('updateFormSuccess', true)
                                                })
                                                .catch(e => {
                                                    this.showError(e)
                                                })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            // Error
            showError(e) {
                if (e.status == 422) {
                    if (e.data.errors.date) this.$refs.formModalValidate.errors.date.push(e.data.errors.date[0]);
                    if (e.data.errors.start_time) this.$refs.formModalValidate.errors.start_time.push(e.data.errors.start_time[0]);
                    if (e.data.errors.end_time) this.$refs.formModalValidate.errors.end_time.push(e.data.errors.end_time[0]);
                    if (e.data.errors.meeting_duration) this.$refs.formModalValidate.errors.meeting_duration.push(e.data.errors.meeting_duration[0]);
                    if (e.data.errors.instructors) this.$refs.formModalValidate.errors.instructors.push(e.data.errors.instructors[0]);
                    if (e.data.errors.available_hours) this.$refs.formModalValidate.errors.exam_type.push(e.data.errors.exam_type[0]);
                }
                else if (e.status == 406) {
                    this.$toast.error(this.$t('api.' + error.data.message));
                }
            }
        }
    }
</script>

