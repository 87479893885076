<template>
    <div class="max">
        <div class="spinner over" v-show="formLoading">
            <b-spinner
                label="Spinning"
                class="text-primary">
            </b-spinner>
        </div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col sm="6" md="4">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input v-model="formData.name"
                                              :class="errors[0] ? 'is-invalid':''">
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"></b-form-invalid-feedback>
                        </ValidationProvider>
                        <ValidationProvider name="name_en" v-slot="{valid, errors}">
                            <b-input-group prepend="EN">
                                <b-form-input v-model="formData.name_en"
                                              :class="errors[0] ? 'is-invalid':''">
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group :label="$t('explanation')">
                        <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-textarea v-model="formData.explanation"
                                                 rows="3"
                                                 :class="errors[0] ? 'is-invalid':''"
                                                 no-resize>
                                </b-form-textarea>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"></b-form-invalid-feedback>
                        </ValidationProvider>
                        <ValidationProvider name="explanation_en" v-slot="{valid, errors}">
                            <b-input-group prepend="EN">
                                <b-form-textarea v-model="formData.explanation_en"
                                                 rows="3"
                                                 no-resize>
                                </b-form-textarea>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('type')">
                            <b-form-select v-model="formData.type"
                                           :options="types"
                                           :class="errors[0] ? 'is-invalid':''">
                            </b-form-select>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="exam_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('exam_method')">
                            <b-form-select v-model="formData.exam_type"
                                           :options="examTypes"
                                           :class="errors[0] ? 'is-invalid':''">
                            </b-form-select>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="language" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('language')">
                                <language-selectbox :validate-error="errors[0]" :setOptions="[
                                    { value: 'turkish', text: $t('turkish') },
                                    { value: 'english', text: $t('english') }]" 
                                    v-model="formData.language">
                                </language-selectbox>
                            </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col sm="6" md="4">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="formData.academic_year"
                                                      :validate-error="errors[0]">
                            </academic-years-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('date')">
                            <select-date v-model="formData.date" :validation-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <b-row>
                        <b-col cols="12" md="6">
                            <ValidationProvider name="start_time" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('start_time')">
                                    <time-picker
                                        :minuteIncrement="5"
                                        :is-range="false"
                                        v-model="formData.start_time"
                                        :validation-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="6">
                            <ValidationProvider name="end_time" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('end_time')">
                                    <time-picker
                                        :minuteIncrement="5"
                                        :is-range="false"
                                        v-model="formData.end_time"
                                        :validation-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="6">
                            <ValidationProvider name="from_date" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('exam_application_start_date')">
                                    <select-date v-model="formData.from_date" :validation-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="6">
                            <ValidationProvider name="to_date" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('exam_application_start_date')">
                                    <select-date v-model="formData.to_date" :validation-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <ValidationProvider name="duration" rules="min_value:0" v-slot="{valid, errors}">
                        <b-form-group :label="$t('duration')">
                            <b-input-group :append="$t('minute')">
                                <b-form-input v-model="formData.duration"
                                              type="number"
                                              min="0"
                                              v-check-min-max-value
                                              :class="errors[0] ? 'is-invalid':''">
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="info_url" v-slot="{valid, errors}">
                        <b-form-group :label="$t('info_link')">
                            <b-form-input v-model="formData.info_url"
                                          :class="errors[0] ? 'is-invalid':''">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="file" v-slot="{valid, errors}">
                        <b-form-group :label="$t('file')">
                            <b-input-group>
                                <b-form-file
                                    v-model="formData.file"
                                    :placeholder="formData.file_name ? formData.file_name:$t('select_file')"
                                >
                                    <b-form-file id="file-small"></b-form-file>
                                </b-form-file>
                                <b-input-group-append>
                                    <b-button variant="outline-secondary" class="btn-40 download"
                                              :v-b-popover.hover.right="$t('download')"
                                              v-if="formData.file_uuid"
                                              @click="downloadFile(formData.file_uuid)" >
                                        <i class="ri-download-2-line"></i>
                                    </b-button>
                                    <b-button variant="outline-secondary"
                                              class="btn-40"
                                              :v-b-popover.hover.right="$t('upload')">
                                        <i class="ri-add-fill"></i>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col sm="6" md="4">
                    <ValidationProvider name="included_registration_types" v-slot="{valid, errors}">
                        <b-form-group :label="$t('included_registration_types')">
                            <checkbox-with-filter v-model="formData.included_registration_types"
                                                  :options="registrationTypes">
                            </checkbox-with-filter>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="included_registration_types" v-slot="{valid, errors}">
                        <b-form-group :label="$t('excluded_registration_types')">
                            <checkbox-with-filter v-model="formData.excluded_registration_types"
                                                  :options="registrationTypes">
                            </checkbox-with-filter>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="createForm" v-if="formProgress=='create'">{{ $t('save') }}</b-button>
                <b-button variant="primary" @click="updateForm" v-if="formProgress=='update'">{{ $t('save') }}</b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
    import CheckboxWithFilter from "@/components/elements/CheckboxWithFilter";
    import TimePicker from "@/components/elements/TimePicker.vue";

    // Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"

    // Services
    import PlacementExamService from "@/services/PlacementExamService"
    import SelectDate from "@/components/interactive-fields/SelectDate";

    export default {
        components: {
            SelectDate,
            AcademicYearsSelectbox,
            CheckboxWithFilter,

            ValidationProvider,
            ValidationObserver,
            TimePicker
        },
        props: {
            formId: {
                type: Number,
                default: null
            },
            formProgress: {
                type: String,
                default: 'create',
            },
            types: {
                type: Array,
                default: null,
            },
            examTypes: {
                type: Array,
                default: null,
            },
            registrationTypes: {
                type: Array,
                default: null,
            }
        },
        data() {
            return {
                formData: {
                    name: null,
                    name_en: null,
                    explanation: null,
                    explanation_en: null,
                    type: null,
                    exam_type: null,
                    academic_year: null,
                    date: null,
                    start_time: null,
                    end_time: null,
                    from_date: null,
                    to_date: null,
                    duration: null,
                    info_url: null,
                    included_registration_types: [],
                    excluded_registration_types: [],
                    file: null,
                    fileUrl: null,
                },
                formLoading:false
            }
        },
        created() {
            if(this.formId){
                this.getForm(this.formId);
            }
        },
        methods: {
            formDataClear() {
                this.formData = {
                    name: null,
                    name_en: null,
                    explanation: null,
                    explanation_en: null,
                    type: null,
                    exam_type: null,
                    academic_year: null,
                    date: null,
                    start_time: null,
                    end_time: null,
                    from_date: null,
                    to_date: null,
                    duration: null,
                    info_url: null,
                    included_registration_types: [],
                    excluded_registration_types: [],
                    file: null,
                    fileUrl: null,
                }
                this.$refs.formModalValidate.reset();
            },

            // Get
            getForm(id) {
                this.formLoading = true
                PlacementExamService.get(id)
                                    .then(response => {
                                        let data = response.data.data;
                                        this.formData = {
                                            name: data.name,
                                            name_en: data.name_en,
                                            explanation: data.explanation,
                                            explanation_en: data.explanation_en,
                                            type: data.type,
                                            exam_type: data.exam_type,
                                            academic_year: data.academic_year,
                                            date: data.date,
                                            start_time: data.start_time,
                                            end_time: data.end_time,
                                            from_date: data.from_date,
                                            to_date: data.to_date,
                                            duration: data.duration,
                                            info_url: data.info_url,
                                            included_registration_types: data.included_registration_types ? data.included_registration_types:[],
                                            excluded_registration_types: data.excluded_registration_types ? data.excluded_registration_types:[],
                                            file: null,
                                            file_uuid:  data.file && data.file.uuid ? data.file.uuid:null,
                                            file_name:  data.file && data.file.name ? data.file.name:null,
                                            language: data.language
                                        }
                                        this.formLoading = false;
                                    });
            },

            // Create
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    let formData = this.setFormData('create');

                    setTimeout(() => {
                        PlacementExamService.store(formData)
                                            .then(response => {
                                                this.$toast.success(this.$t('api.' + response.data.message));
                                                this.$refs.formModalValidate.reset();
                                                this.formDataClear();
                                                this.$emit('createFormSuccess', true)
                                            })
                                            .catch(e => {
                                               this.showError(e);
                                            })
                    }, 1000);
                }
            },

            // Update
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    let formData = this.setFormData('edit');
                    PlacementExamService.update(this.formId, formData)
                                        .then(response => {
                                            this.$toast.success(this.$t('api.' + response.data.message));
                                        })
                                        .then(() => {
                                            this.formDataClear();
                                            this.$emit('updateFormSuccess', true)
                                        })
                                        .catch(e => {
                                            this.showError(e)
                                        })
                }
            },

            // Set FormData
            setFormData(method){
                let formData = new FormData();
                if(this.formData.name)              formData.append('name',this.formData.name)
                if(this.formData.name_en)           formData.append('name_en',this.formData.name_en)
                if(this.formData.explanation)       formData.append('explanation',this.formData.explanation)
                if(this.formData.explanation_en)    formData.append('explanation_en',this.formData.explanation_en)
                if(this.formData.type)              formData.append('type',this.formData.type)
                if(this.formData.exam_type)         formData.append('exam_type',this.formData.exam_type)
                if(this.formData.academic_year)     formData.append('academic_year',this.formData.academic_year)
                if(this.formData.date)              formData.append('date',this.formData.date)
                if(this.formData.start_time)        formData.append('start_time',this.formData.start_time)
                if(this.formData.end_time)          formData.append('end_time',this.formData.end_time)
                if(this.formData.from_date)         formData.append('from_date',this.formData.from_date)
                if(this.formData.to_date)           formData.append('to_date',this.formData.to_date)
                if(this.formData.duration)          formData.append('duration',this.formData.duration)
                if(this.formData.info_url)          formData.append('info_url',this.formData.info_url)
                if(this.formData.language)          formData.append('language',this.formData.language)

                if(this.formData.included_registration_types && this.formData.included_registration_types.length>0){
                    this.formData.included_registration_types.forEach((item) => {
                        formData.append('included_registration_types[]',item)
                    })
                }
                if(this.formData.excluded_registration_types && this.formData.excluded_registration_types.length>0){
                    this.formData.excluded_registration_types.forEach((item) => {
                        formData.append('excluded_registration_types[]',item)
                    })
                }
                if(this.formData.file)  formData.append('file',this.formData.file)

                if(method=='edit'){
                    formData.append('_method','PUT')
                }

                return formData;
            },

            // Error
            showError(e){
                if(e.status==422){
                    if (e.data.errors.name) this.$refs.formModalValidate.errors.name.push(e.data.errors.name[0]);
                    if (e.data.errors.name_en) this.$refs.formModalValidate.errors.name_en.push(e.data.errors.name_en[0]);
                    if (e.data.errors.explanation) this.$refs.formModalValidate.errors.explanation.push(e.data.errors.explanation[0]);
                    if (e.data.errors.explanation_en) this.$refs.formModalValidate.errors.explanation_en.push(e.data.errors.explanation_en[0]);
                    if (e.data.errors.type) this.$refs.formModalValidate.errors.type.push(e.data.errors.type[0]);
                    if (e.data.errors.exam_type) this.$refs.formModalValidate.errors.exam_type.push(e.data.errors.exam_type[0]);
                    if (e.data.errors.academic_year) this.$refs.formModalValidate.errors.academic_year.push(e.data.errors.academic_year[0]);
                    if (e.data.errors.date) this.$refs.formModalValidate.errors.date.push(e.data.errors.date[0]);
                    if (e.data.errors.start_time) this.$refs.formModalValidate.errors.start_time.push(e.data.errors.start_time[0]);
                    if (e.data.errors.end_time) this.$refs.formModalValidate.errors.end_time.push(e.data.errors.end_time[0]);
                    if (e.data.errors.from_date) this.$refs.formModalValidate.errors.from_date.push(e.data.errors.from_date[0]);
                    if (e.data.errors.to_date) this.$refs.formModalValidate.errors.to_date.push(e.data.errors.to_date[0]);
                    if (e.data.errors.duration) this.$refs.formModalValidate.errors.duration.push(e.data.errors.duration[0]);
                    if (e.data.errors.info_url) this.$refs.formModalValidate.errors.info_url.push(e.data.errors.info_url[0]);
                    if (e.data.errors.included_registration_types) this.$refs.formModalValidate.errors.included_registration_types.push(e.data.errors.included_registration_types[0]);
                    if (e.data.errors.excluded_registration_types) this.$refs.formModalValidate.errors.excluded_registration_types.push(e.data.errors.excluded_registration_types[0]);
                    if (e.data.errors.file) this.$refs.formModalValidate.errors.file.push(e.data.errors.file[0]);
                }
                else if(e.status==406){
                    this.$toast.error(this.$t('api.' + e.data.message));
                }
            },

            // DownloadFile
            downloadFile(uuid){
                PlacementExamService.downloadFile(uuid)
                                   .then(response => {
                                       this._downloadFile(response, this.formData.name+".pdf");
                                   })
                                   .catch(error => {
                                       if (error.status == 422) {
                                           this.$toast.error(error.data.errors.file[0]);
                                       }
                                       if (error.status == 406) {
                                           this.$toast.error(this.$t('api.' + error.data.message));
                                       }
                                   });
            }
        }
    }
</script>

