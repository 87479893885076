<template>
    <div>
        <ValidationObserver ref="fileImport">
            <b-row>
                <b-col cols="12" md="6" lg="12">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="form.semester_id">
                            </semesters-selectbox>
                            <b-form-invalid-feedback v-for="(err, index) in errors" :key="index"> {{ err }} </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col rows="12" md="6" lg="12">
                    <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('excel_file')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file
                                    v-model="form.file"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="$t('select_file')"
                                    :drop-placeholder="$t('drop_file')"
                                    ref="fileInput"
                                    multiple></b-form-file>
                                <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
                            </div>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            <div class="alert alert-info mt-2">{{ $t('placement_exam_scores_excel') }}</div>

                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col rows="12" md="6" lg="12">
                    <div class="d-flex justify-content-center mb-5">
                        <b-button variant="primary" @click="upload">
                            {{ $t('upload').toUpper() }}
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script >
import PlacementExamService from "@/services/PlacementExamService";

export default {
    props:{
        formId:{
            type:Number
        }
    },
    data(){
        return{
            form:{}
        }
    },
    methods:{
        async  upload(){
            let isValid= await this.$refs.fileImport.validate()
            if(isValid){
                let file =this.form.file
                let fileName=file[0]
                let formData=new FormData()
                formData.append('file',fileName)
                formData.append('semester_id',this.form.semester_id)
                PlacementExamService.ImportExcel(this.formId,formData)
                    .then(response=>{
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                    }).catch(err=>{
                    this.showErrors(err)
                })
            }

        }
    }
}
</script>
